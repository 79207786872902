/* ==========================================================================
 # RESET
========================================================================== */

* {
    box-sizing: border-box;
}

html {
    box-sizing: border-box;
    font-size: 16px;
}

*, *:before, *:after {
    box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
    margin: 0;
    padding: 0;
}

ol, ul {
    list-style: none;
}

img {
    max-width: 100%;
    height: auto;
}


/* ==========================================================================
 # BASE
========================================================================== */

:root {
    --dark-0:   #2c2c2c;
    --dark-100: #71888e;
    --dark-200: #45484c;
    --dark-300: #5c676e;
    --accent-color: #9f6372;
    --accent-color-100: #dc8573;
    --white-100: #dfd8d8;
}


h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

h3 {
    font-size: 1.1rem;
    margin-bottom: 0.8rem;
}

fieldset {
    border: none;
    padding: 0 0 1rem 0;
}

button {
    color: white;
    background: var(--accent-color);
    border: none;
    padding: 0.5rem 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.3s;
    border-radius: 3px;
}

button:hover {
    background: var(--accent-color-100);

}

.App {
    height: 100vh;
    background: var(--dark-200);
    display: flex;
    flex-direction: column;
    color: var(--white-100);
    font-size: 15px;
}

a {
    color: white;
    text-decoration: none;
}

header {
    background: var(--dark-300);
    padding: 0.3rem;
    display: flex;
    justify-content: space-between;
}

header img {
    width: 180px;
    height: auto;
}

nav {
    display: flex;
    gap: 1.5rem;
    align-items: center;
}

/* ==========================================================================
 #  Editor
========================================================================== */
.editor {
    flex: 1 1 100%;
}

.editor__bottom-bar {
    display: flex;
    height: 40px;
    flex: none;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    background: var(--dark-200);
    padding: 7px;
    font-size: 0.9rem;
}
.editor__bottom-bar:not(:first-of-type)
{
    border-top: 5px solid var(--dark-300);
}

.editor__bottom-bar label {
    color: rgba(255, 255, 255, 0.7);
    display: inline;
    margin: 0;
}

.editor__bottom-bar label + label {
    padding-left: 1rem;
}

.editor__bottom-bar h3 {
    opacity: 0.3;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.8rem;
    margin-left: 10px;
    margin-bottom: 0;
}

/* ==========================================================================
 #  Modal
========================================================================== */

.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    background: var(--dark-200);
    padding: 1rem;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    width: 400px;
    max-width: 90%;
    position: relative;
}

.modal__close {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
}

.modal__close svg {
    fill: white;
    width: 20px;
    height: 20px;
    pointer-events: none;
}


/* ==========================================================================
 # FORM ELEMENTS
========================================================================== */
label {
    display: block;
    margin-bottom: 0.5rem;
}

.switch {
    display: inline-flex;
    align-items: center;
    margin-left: -4px;
    width: 100%;
}

.switch__slider {
    position: relative;
    display: inline;
    width: 50px;
    margin-right: 5px;
    height: 24px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
}

/* The slider */
.switch__slider-handle {
    border-radius: 24px;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--dark-0);
    -webkit-transition: .4s;
    transition: .4s;
}

.switch__slider-handle:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
    background: var(--dark-300);
}

input:checked + .switch__slider .switch__slider-handle {
    background-color: #00b38f;
}

input:focus + .switch__slider .switch__slider-handle {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .switch__slider .switch__slider-handle:before {
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

input {
    background: var(--dark-0);
    border-radius: 3px;
    color: rgba(255, 255, 255, 0.7);
    padding: 5px;
    border: 1px solid transparent;
    margin-right: 10px;
}

input:focus {
    outline: none;
    border: 1px solid var(--accent-color);
}

/* ==========================================================================
 # Docs
========================================================================== */

.docs {
    z-index: 10;
    position: fixed;
    top: 61px;
    right:  0;
    width: 50%;
    height: calc(100% - 61px);
    padding: 1rem;
    background: var(--dark-200);
    transform: translateX(100%);
    transition: transform 0.3s;
    overflow: scroll;
    border-left: 2px solid var(--dark-300);
}

.docs input {
    width: 100%;
    margin: 1rem 0;
    padding: 0.5rem;
}

.docs.is-active {
    transform: translateX(0);
}

.docs h4 {
    color: var(--accent-color-100);
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 0.8rem;
    margin-top: 10px;
    margin-bottom: 3px;
}

.docs pre  {
    background: var(--dark-0);
    padding: 0.5rem;
    border-radius: 3px;
    color: #a9b7c7;
    margin-top: 0;
    display: block;
    overflow: scroll;
    font-size: 0.8rem;
}

summary {
    background-color: var(--dark-300);
    color: white;
    cursor: pointer;
    padding: 10px;
}

details section {
    padding: 10px;
    border-radius: 5px;
}

details {
    border: 1px solid var(--dark-300);
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 1rem;
}