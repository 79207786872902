.hljs-keyword {
  color: #9975ab;
}

.hljs-delimiter-tag {
  color: #ce7827;
}

.hljs-variable {
  color: #7bff79;
}

.hljs-variable-wild {
  color: #7bff79;
}

.hljs-number {
  color: #6695bc;
}

.hljs-string {
  color: #698857;
}

.hljs-operator {
  color: #c3cdd9;
}

.hljs-identifier {
  color: #ffc765;
}

